import React, { useState, useEffect, useRef } from 'react'

import {
  WrapBanner,
  Content,
  Title,
  Text,
  Button,
  BannerTextBlock,
  BannerImageBlock,
  BannerImage1,
  BannerImage2,
  BannerImage3
} from './styles'
import Img from 'gatsby-image'

import bannerImg1 from '../../../assets/images/RBEbanner.png'

const Banner = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/booking-engine'
  }

  return (
    <WrapBanner>
      <Content>
        <BannerTextBlock>
          <Title>Što je Hotel Booking Engine?</Title>
          <Text>
            Hotel Booking Engine je rješenje koje gostima omogućuje online rezervaciju hotelskih soba, apartmana ili
            drugog smještaja putem web stranice hotela. To je digitalna platforma koja gostima omogućuje jednostavno
            traženje slobodnih soba, odabir datuma i tipova soba, pregled cijena te, na kraju, rezervaciju izravno putem
            web stranice.
          </Text>
          <Button onClick={(e) => handleClick(e)}>Saznaj više</Button>
        </BannerTextBlock>
        <BannerImageBlock>
          <img src={bannerImg1} />
        </BannerImageBlock>
      </Content>
    </WrapBanner>
  )
}

export default Banner
