import React, { useState, useEffect, useRef } from 'react'

import {
  WrapContent,
  MiddleTextContent,
  IntroText,
  GreyBlock,
  H2,
  H3,
  P,
  PUL,
  PMSsustavIMG,
  FullWidthImg,
  ImgTitle,
  MiddleContent,
  GradientText,
  SideText,
  ButtonCTA,
  UnderButtonText,
  LeftColumn,
  TopText,
  LeftLink,
  BlueLine,
  WrapLeftContent
} from './styles'
import Img from 'gatsby-image'

import HBEbe from '../../../assets/images/HBEbe.png'
import HBEanalitika from '../../../assets/images/HBEanalitika.png'
import Kokpit from '../../../assets/images/Kokpit.png'
import Kalendar from '../../../assets/images/Kalendar.png'
import Statistika from '../../../assets/images/Statistika.png'
import MobileApps from '../../../assets/images/MobileApps.png'
import RadarGroup from '../../../assets/images/RadarGroup.png'
import Integrations from '../../../assets/images/integrationsAuthority.png'
import Form from '../../AuthorityForm'

const links = [
  {
    id: 1,
    title: 'Ključne funkcionalnosti Hotel Booking Engine-a'
  },
  {
    id: 2,
    title: 'Važnost integriranog hotel booking engine-a i PMS sustava'
  },
  {
    id: 3,
    title: 'Što je Rentlio Hotel Booking Engine?'
  }
]

const Content = ({ locale }) => {
  useEffect(() => {
    var links = document.querySelectorAll('.link')
    var sections = document.querySelectorAll('.sections')
    window.onscroll = function () {
      var current = 'home'
      sections.forEach((section) => {
        const sectionTop = section.offsetTop
        if (pageYOffset >= sectionTop - 200) {
          current = section.getAttribute('id')
        }
      })
      links.forEach((item) => {
        item.classList.remove('active')
        if (item.href.slice(-1) === current) {
          item.classList.add('active')
        } else {
          item.classList.remove('active')
        }
      })
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    window.location = 'https://rentl.io/property-management-system'
  }
  return (
    <WrapContent>
      <LeftColumn>
        <WrapLeftContent>
          <BlueLine />
          <TopText>Teme unutar stranice</TopText>
          {links.map((link) => (
            <LeftLink key={link.id}>
              <a className="link" href={`#${link.id}`}>
                {link.title}
              </a>
            </LeftLink>
          ))}
        </WrapLeftContent>
      </LeftColumn>
      <MiddleTextContent>
        <H2 id="1" className="sections">
          Ključne funkcionalnosti Hotel Booking Engine-a
        </H2>
        <H3>Dostupnost u stvarnom vremenu:</H3>
        <P>
          Jedna od ključnih prednosti hotelskog booking engine-a je pružanje informacije o raspoloživosti soba u
          stvarnom vremenu. Tako zainteresirani gosti mogu vidjeti trenutnu dostupnost soba za datume koje žele
          rezervirati i mogu odmah rezervirati.
        </P>
        <H3>Jednostavno pretraživanje i proces rezervacije:</H3>
        <P>
          Hotel booking engine treba imati sučelje koje gostima omogućuje jednostavno pretraživanje slobodnih soba,
          odabir željene vrste smještaja i datuma, pregled cijena i rezervaciju boravka u samo nekoliko klikova.
        </P>
        <H3>Više opcija plaćanja:</H3>
        <P>
          Kvalitetan hotel booking engine nudi više opcija plaćanja gostima; uključujući kreditne kartice, debitne
          kartice i pouzdane online načine plaćanja. Tako gosti odabiru način plaćanja koji im najviše odgovara.
        </P>
        <H3>Dizajn prilagođen mobilnim uređajima:</H3>
        <P>
          Sve više i više gostiju koristi mobilne uređaje za rezervaciju hotelskih soba. Stoga bi hotel booking engine
          trebao imati dizajn prilagođen mobilnim uređajima, posebno optimiziran za pametne telefone i tablete.
        </P>
        <H3>Personalizacija:</H3>
        <P>
          Hotel Booking Engine personalizacijom pruža gostima prilagođeno iskustvo rezerviranja. Primjerice, može
          ponuditi preporuke temeljem prethodnih rezervacija tog gosta ili posebne ponude i promocije na temelju
          preferencija gosta.
        </P>
        <H3>Integracija s društvenim mrežama:</H3>
        <P>
          Integracijom Hotel Booking Engine-a s društvenim mrežama omogućujete gostima da podijele informaciju o
          rezervaciji u vašem hotelu, čime postižete pozitivan efekt na online reputaciju.
        </P>
        <FullWidthImg>
          <img src={HBEbe} />
        </FullWidthImg>
        <ImgTitle>Rentlio Booking Engine</ImgTitle>
        <GreyBlock id="2" className="sections">
          <H2>Važnost integriranog hotel booking engine-a i PMS sustava</H2>
          <P>
            U hotelijerstvu i turizmu,{' '}
            <a href="https://rentl.io/property-management-system">property management sustav (PMS)</a> i
            <a href="https://rentl.io/booking-engine"> hotel booking engine (HBE)</a> dvije su najvažnije tehnologije
            koje hotelijeri koriste kako bi upravljali operacijama i povećali prihode. Ova su dva sustava neophodna za
            upravljanje rezervacijama, inventarom soba i informacijama o gostima. No, zašto je važno da su ova dva
            sustava integrirana? Pogledajmo u nastavku.
          </P>
          <H3>Što je hotel PMS?</H3>
          <P>
            <a href="https://rentl.io/hotel-property-management-system">Property management sustav (PMS)</a> je
            softverska aplikacija koju koriste hotelijeri kako bi upravljali svakodnevnim operacijama i poslovanjem. Od
            upravljanja rezervacijama i popisom soba do podataka o gostima i obrade plaćanja, hotel property management
            sustav središte je svih operacija i omogućuje osoblju upravljanje svim aspektima iskustva gostiju iz jednog
            mjesta.
          </P>
          <P>
            Integracija Booking engine-a i PMS-a ključna je za hotele koji žele pojednostaviti poslovanje i povećati
            prihode. U nastavku su neki od ključnih razloga:
          </P>
          <H3>Povećana produktivnost</H3>
          <P>
            Integracijom hotelskog PMS-s i booking engine-a, osoblje upravlja svim aspektima iskustva gostiju koristeći
            jednu platformu. To eliminira prebacivanje između više različitih rezervacijskih sustava kako bi se
            upravljalo inventarom soba i podacima o rezervacijama. Centralizacija svih podataka i rezervacija na jednom
            mjestu omogućuje osoblju efikasniji rad i brže odgovaranje na zahtjeve gostiju.
          </P>
          <H3>Viši prihodi</H3>
          <P>
            Integracijom PMS-a i HBE-a hoteli mogu povećati prihode dopuštajući gostima direktnu rezervaciju putem web
            stranice ili mobilne aplikacije. Ovo neće samo smanjiti provizije i naknade na rezervacije ostalih
            distribucijskih kanala, već će pružiti hotelima priliku da gostima ponude dodatne usluge poput upgrade-a
            soba ili spa tretmana.
          </P>
          <H3>Kvalitetnija iskustva gostiju</H3>
          <P>
            Integracija PMS-a i hotel booking engine-a također poboljšava iskustvo gostiju besprijekornim procesom
            rezerviranja. Gosti mogu jednostavno pretraživati dostupne sobe, odabrati datume i tipove soba, pregledati
            cijene i na kraju rezervirati svoj boravak izravno putem hotelske web stranice ili mobilne aplikacije. Ovo
            pruža personaliziranije i praktičnije iskustvo za goste, što može dovesti do veće lojalnosti i ponovljenog
            boravka.
          </P>
          <H3>Pouzdana izvješća i analitika</H3>
          <P>
            Integracija property management sustava i booking engine-a hotelijerima pruža kvalitetnije mogućnosti
            korištenja analitike i podataka. Uz sve podatke na jednom mjestu, voditelji prodaje, direktori i hotelijeri
            lako prate tijek prihoda, stope popunjenosti i druge ključne pokazatelje uspješnosti poslovanja hotela.
            Takvi podaci dragocjeni su prilikom donošenja odluka o cijenama, marketingu i drugim aspektima poslovanja.
          </P>
          <FullWidthImg>
            <img src={HBEanalitika} />
          </FullWidthImg>
        </GreyBlock>
        <Form locale="hr" />
        <H2 id="3" className="sections">
          Što je Rentlio Hotel Booking Engine?
        </H2>
        <P>
          <a href=" https://rentl.io/booking-engine">Rentlio Hotel Booking Engine</a> je cloud-based rješenje. HBE je
          potpuno fleksibilan sustav online rezervacija bez provizije za hotele, resorte i odmarališta. Booking Engine
          hotelijerima omogućuje upravljanje rezervacijama, inventarom soba, cijenama i zauzećem u stvarnom vremenu,
          istovremeno pružajući besprijekorno iskustvo gostima.
        </P>
        <P>
          Rentlio Booking Engine omogućuje hotelijerima jednostavno upravljanje direktnim rezervacijama i inventarom s
          jednog mjesta. Podržava više jezika i valuta što olakšava pružanje usluga gostima iz različitih dijelova
          svijeta. Dodatno, responzivnost dizajna Booking Engine-a prilagođena je mobilnim uređajima kako bi gosti mogli
          rezervirati svoj boravak služeći se bilo kojim uređajem, uključujući stolna računala, tablete i pametne
          telefone.
        </P>
        <P>
          Jedna od ključnih značajki Rentlio Booking Engine-a je fleksibilnost i mogućnosti prilagodbe. Hotelijeri mogu
          prilagoditi Booking Engine kako bi vizualno odgovarao njihovom brendu, uz mogućnosti dodavanja prilagođenih
          logotipa, boja i drugih elemenata dizajna. Rentlio Booking Engine također podržava različite tipove soba,
          modele cijena i promocija, omogućujući hotelijerima stvaranje jedinstvenog i personaliziranog iskustva
          rezerviranja za svoje goste.
        </P>
        <P>
          Rentlio Booking Engine nudi i niz statističkih funkcionalnosti kako bi se hotelijerima omogućilo pouzdano
          praćenje poslovanja i donošenja odluka temeljem podataka. Uz izvješća u stvarnom vremenu o rezervacijama,
          prihodima, stopama popunjenosti i drugim ključnim pokazateljima, hotelijeri lako mogu identificirati trendove,
          optimizirati cijene i marketinške strategije.
        </P>
        <P>
          Sve u svemu, Rentlio Booking Engine sveobuhvatno je rješenje za hotelijere koji žele pojednostaviti
          rezervacijske procese i povećati prihode. To je rezervacijski sustav koji je prilagodljiv i jednostavan za
          korištenje i hotelijerima i gostima, uz napredne funkcionalnosti i analitiku kako bi hoteli kvalitetno
          konkurirali i unaprijedili poslovanje.
        </P>

        <ButtonCTA onClick={(e) => handleClick(e)}>Saznajte više o Rentlio PMS-u</ButtonCTA>
        <UnderButtonText>Počnite koristiti svoj budući PMS, već danas!</UnderButtonText>
      </MiddleTextContent>
    </WrapContent>
  )
}

export default Content
